<template>
  <router-view v-slot="{ Component, route }">
    <ReloadPWA />
    <ReloadIntercom />
    <LoadHotjar />
    <transition
      :name="route.meta.transition || ''"
      mode="out-in"
    >
      <div :key="route.name">
        <component
          :is="Component"
          v-if="appLoaded"
          :key="accountStore.reload"
        />
      </div>
    </transition>
  </router-view>
</template>

<script lang="ts">
import { defineComponent } from "vue"

import ReloadPWA from "@/components/ReloadPWA.vue"
import ReloadIntercom from "./components/ReloadIntercom.vue"
import LoadHotjar from "@/components/LoadHotjar.vue"

import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"
import { useAuthenticationStore } from "@/stores/authentication"
import { useEstablishmentStore } from "@/stores/establishment"
import { useRoute } from "vue-router"

export default defineComponent({
  name: "App",

  components: { LoadHotjar, ReloadPWA, ReloadIntercom },

  computed: {
    ...mapStores(useAccountStore, useAuthenticationStore, useEstablishmentStore),

    appLoaded() {
      let loaded = true

      // cas authentifié, il faut attendre que le initApp() se termine
      if (this.authenticationStore.user && this.authenticationStore.user!.email != undefined) {
        loaded = this.accountStore.establishmentLoaded
      }

      const disabledAuthRoutes = ['/error/version', 'authentication', '/welcome']

      if (disabledAuthRoutes.includes(this.$route.path)) {
        loaded =  true
      }

      return loaded
    }
  },

  mounted() {
    // init app data for auth user (not login as)

    const route = useRoute()
    if (route.query.token === null) {
      this.initApp()
    } else {
      setTimeout(() => {
        this.initApp()
      }, 1000)
    }
  },

  methods: {
    initApp() {
      if (this.authenticationStore.user && this.authenticationStore.user!.email != undefined) {
        this.accountStore.getEstablishments(() => {
          const currentEstablishment = this.establishmentStore.currentEstablishment

          if (currentEstablishment) {
            // needed to refresh product plan if updated on nova
            this.establishmentStore.getCurrentEstablishment(currentEstablishment.id)
          }

          const language = this.authenticationStore.user.language

          if (language) {
            this.$i18n.locale = this.authenticationStore.user.language
            this.$traduction.changeLang(language)
          }

          this.accountStore.establishmentLoaded = true
        }, false)
      }
    },
  },
})
</script>
